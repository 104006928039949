<template>
    <div class="auto-complete-box">
        <input
            ref="input"
            :value="value"
            @input="handleInputChange($event)"
            :placeholder="placeholder"
            @blur="handleBlur"
            @focus="handleFocus"
            @keydown.exact.enter="handleReturn"
            @keypress="queryChange"
            @keydown.backspace="queryChange"
            @keydown.exact.up="sugUpdate(-1, $event)"
            @keydown.exact.down="sugUpdate(1, $event)"
        />
        <div class="serp-suggestions" v-if="hasResults && hasFocus">
            <div
                class="serp-suggestion"
                :class="{ active: idx == realSelected }"
                @click="handleClick(s, $event)"
                v-for="(s, idx) in items"
            >
                <div class="sug-info">
                    <h4 class="sug-title" v-html="s.title"></h4>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import debounce from "lodash.debounce";
import trim from "lodash.trim";
import { suggestionProvider } from "./lib/autocomplete";

export default {
    props: ["value", "placeholder"],
    data() {
        return {
            results: false,
            selected: false,
            errors: false,
            hasFocus: false,
            issued: false,
            query: "",
        };
    },
    methods: {
        handleInputChange(e) {
            this.$emit("input", e.target.value);
        },
        focus() {
            this.$refs.input.focus();
        },
        blur() {
            this.$refs.input.blur();
        },
        handleBlur() {
            window.setTimeout(() => {
                this.hasFocus = false;
            }, 500);
            this.$emit("blur");
        },
        handleFocus() {
            this.$nextTick(() => {
                this.hasFocus = true;
            });
            this.$emit("focus");
        },
        handleReturn() {
            // If something is selected we will pick it.
            if (this.selected !== false) {
                const elt = this.items[this.realSelected];
                this.$emit("input", elt.title);
                this.$emit("selected");
            } else {
                this.$emit("return");
            }
        },
        handleClick(item, e) {
            this.$emit("input", item.title);
            this.$emit("selected");
        },
        sugUpdate(count, e) {
            e.preventDefault();
            if (count == false) {
                this.selected = false;
                return;
            }
            if (this.selected === false) {
                this.selected = count < 0 ? -1 : 0;
                if (typeof this.items[this.selected] !== "undefined")
                    this.$emit("input", this.items[this.selected].title);
                return;
            }
            this.selected += count;
            if (typeof this.items[this.selected] !== "undefined")
                this.$emit("input", this.items[this.selected].title);
        },
        async queryChange(e) {
            if (e.keyCode != 13) {
                if (this.issued) {
                    this.issued = false;
                    this.request(false);
                    return;
                }
                if (this.results) {
                    if (this.query.length > this.value.length) {
                        this.clear();
                    }
                }
                await this.request();
            } else {
                this.$refs.input.blur();
            }
        },
        clear() {
            this.results = false;
            this.selected = false;
        },
        request: debounce(async function(cancel) {
            const term = trim(this.value);
            const lastResult = this.results;
            if (term === "" || cancel) {
                this.clear();
                return;
            }
            try {
                const res = await suggestionProvider(term);
                if (this.value.indexOf(term) == -1) {
                    if (lastResult == this.results) {
                        this.clear();
                    }
                    return;
                }
                this.results = res.records || [];
            } catch (e) {
                this.results = [];
            }
        }, 300),
    },
    computed: {
        hasResults() {
            return this.results && this.items.length > 0;
        },
        realSelected() {
            if (this.selected === false) return -1;
            if (this.items === false) return -1;
            return this.selected % this.items.length;
        },
        items() {
            return this.results && this.results;
        },
    },
};
</script>

<style>
.auto-complete-box {
    display: inline;
}
</style>
