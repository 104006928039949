const defaultBranding = {
    ProductType: "forms",
    SearchLabel: "Forms Search",
    MetaSearchKeyword: "forms",
    SearchExample: "Taxes, Immigration, US",
    AutocompletePlaceholder: "Search form titles or tags",
    IntroLI1:
        "Search our database for forms by typing in a form query in the search box above.",
    IntroLI2:
        "You can also browse our forms database by 'tag' located on the left side menu of the page.",
};

const windowBranding = window.branding || {};

const PRODUCT_SEARCH_PROVIDERS = {
    default: {
        name: windowBranding.SearchEngineName,
        id: windowBranding.SearchId,
        isDefault: true,
    },
    irs: {
        name: "IRS",
        id: "irs",
        isDefault: false,
        url: "https://www.irs.gov/forms-pubs-search?search={{QUERY}}",
    },
    courts: {
        name: "US Courts",
        id: "courts",
        isDefault: false,
        url: "https://www.uscourts.gov/services-forms/forms?k={{QUERY}}",
    },
};

const altSearchProvidersUrls = function(query) {
    const altProviders = Object.entries(PRODUCT_SEARCH_PROVIDERS).filter(
        ([key, value]) => !value.isDefault
    );
    return altProviders.map(([key, value]) => {
        return {
            name: value.name,
            url: value.url.replace("{{QUERY}}", query),
        };
    });
};

const DEFAULT_PRODUCT_SEARCH_PROVIDER = PRODUCT_SEARCH_PROVIDERS.default.id;

function getBranding() {
    return {
        ...defaultBranding,
        ...windowBranding,
    };
}

export {
    getBranding,
    PRODUCT_SEARCH_PROVIDERS,
    altSearchProvidersUrls,
    DEFAULT_PRODUCT_SEARCH_PROVIDER,
};
