<template>
    <!--main wrapper-->
    <div class="serp-wrapper">
        <!-- Bookmark Popup  -->
        <first-run
            :which="whichPop"
            :onClose="onCloseHelp"
            :onNext="onNextPop"
        />
        <!--header wrapper-->
        <div class="header-wrapper">
            <!--nav-->
            <div id="serp-nav">
                <div
                    id="job-btn"
                    class="serp-nav-item"
                    :class="{ active: productSearch }"
                    @click="toProductSearch()"
                >
                    {{ branding.SearchLabel }}
                </div>
                <div
                    id="web-btn"
                    class="serp-nav-item"
                    v-if="!isFirefox"
                    :class="{ active: !this.productSearch }"
                    @click="toWebSearch()"
                >
                    Web Search
                </div>
            </div>
            <!--End of nav-->
            <!--header-->
            <div class="serp-header">
                <div class="serp-logo"><img :src="branding.Logo" /></div>
                <div class="thisSearch" v-show="productSearch">
                    <div class="search pad-right">
                        <auto-complete-input
                            v-model="query"
                            ref="queryInput"
                            :placeholder="branding.AutocompletePlaceholder"
                            @selected="onSugSelected()"
                            @return="onSugSelected()"
                        />
                        <div class="s-icon">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </div>
                        <div class="error" v-if="queryError">
                            This field is required, please enter
                        </div>
                    </div>
                    <button id="btn-search" @click="onSugSelected()">
                        {{ branding.SearchLabel }}
                    </button>
                </div>
                <div class="webSearch" v-show="!productSearch">
                    <div class="search web">
                        <input
                            ref="webSearch"
                            id=""
                            placeholder="Search the web"
                            v-model="wQuery"
                            @keydown.enter="startWebSearch()"
                        />
                        <div class="s-icon">
                            <i class="fa fa-globe" aria-hidden="true"></i>
                        </div>
                        <div class="error" v-if="queryError">
                            This field is required, please enter
                        </div>
                    </div>
                    <button id="btn-search" @click="startWebSearch()">
                        Web Search
                    </button>
                </div>
            </div>
            <!--End of Header-->
        </div>
        <!--End of header wrapper-->
        <!--serp results-->
        <div class="bodyWrapper">
            <div class="serp-results">
                <!--Left col-->
                <div class="serp-results-left">
                    <!-----First Run Add on------>
                    <intro-box v-show="!hasSearchResults" />
                    <!-----End of First Run Add on------>
                    <div class="serp-stats" v-show="hasSearchResults">
                        Showing {{ numResults }} {{ branding.ProductType }} for
                        <span class="kw"> {{ searchTerm }}</span>
                        <span v-if="filterTag" class="filter">{{
                            filterTag
                        }}</span>
                    </div>
                    <!--NoResults-->
                    <div
                        class="serp-noresults"
                        v-show="
                            !hasSearchResults &&
                                searchTerm != '' &&
                                searchCompleted
                        "
                    >
                        <h3>
                            The search
                            <span class="kw">{{ searchTerm }}</span> did not
                            match any {{ branding.ProductType }}. Try the
                            suggestions below or type a new query above.
                        </h3>
                        <ul>
                            <li>Check your spelling.</li>
                            <li>Try more general keywords</li>
                            <li>Replace abbreviations with the entire word</li>
                        </ul>
                        <h2>Still no results!</h2>
                        <h3 class="bs">
                            We try our best to keep up our database up to date,
                            but unfortunately with hundreds of new
                            {{ branding.ProductType }}
                            released every day, it is sometimes hard to account
                            for all new updates. Try the links below to some
                            other resources that may help with your search.
                        </h3>
                        <br />
                        <p v-for="{ name, url } in altSearchUrls">
                            <a
                                class="other-res"
                                :href="url"
                                :title="name"
                                target="_blank"
                                >{{ name }}</a
                            >
                        </p>
                    </div>
                    <!-- End of NoResults-->

                    <div v-show="hasSearchResults">
                        <!--results-->
                        <div
                            class="srl has-image"
                            v-for="result in filteredSearchResults"
                            @click.prevent="openUrl(result.url, true, false)"
                        >
                            <div
                                v-show="
                                    result.image || branding.FallbackResultIcon
                                "
                                class="srl-img"
                            >
                                <img :src="branding.FallbackResultIcon" />
                            </div>
                            <div class="srl-info">
                                <h3 class="srl-title">
                                    <a :href="result.url" target="_blank">{{
                                        result.title
                                    }}</a>
                                </h3>
                                <div
                                    v-show="result.description"
                                    class="srl-description"
                                >
                                    {{ result.description }}
                                </div>
                                <div v-show="result.tags" class="srl-cat">
                                    <strong>Tags:</strong
                                    >{{ result.tags.join(", ") }}
                                </div>
                                <div
                                    v-show="result.microdata"
                                    v-for="(value, field) in microdataFields(
                                        result.microdata
                                    )"
                                    class="srl-lang"
                                >
                                    <strong>{{ field }}:</strong>{{ value }}
                                </div>
                            </div>
                        </div>
                        <!--end of results-->
                    </div>
                    <!--Browse Panel-->
                    <!--End of left col-->
                </div>
                <!--Right col -->
                <div class="rightCol">
                    <!--Categories-->
                    <div class="module-right filter" v-if="categoryTags">
                        <h2>
                            <i class="fa fa-filter" aria-hidden="true"></i
                            >Categories:
                        </h2>
                        <div class="category custom-select">
                            <ul id="tags">
                                <template v-for="(tagObj, tag) in categoryTags">
                                    <li :class="{ active: filterTag === tag }">
                                        <a @click="doSearch(tag)" :title="tag"
                                            >{{ tag }} ({{ tagObj.total }})</a
                                        >
                                    </li>
                                    <li
                                        :class="{ active: filterTag === tag }"
                                        class="subtag"
                                        v-for="(count, tag) in tagObj.tags"
                                    >
                                        <a @click="doSearch(tag)" :title="tag"
                                            >{{ tag }} ({{ count }})</a
                                        >
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </div>
                    <!--End of filter-->
                    <!--Filter-->
                    <div class="module-right filter" v-if="hasSearchResults">
                        <h2>
                            <i class="fa fa-filter" aria-hidden="true"></i
                            >Filter by tag:
                        </h2>
                        <div class="category custom-select">
                            <ul id="tags">
                                <li>
                                    <a
                                        :class="{ active: !filterTag }"
                                        @click="resetFilterTag()"
                                        title="All"
                                        >All</a
                                    >
                                </li>
                                <template
                                    v-for="(tagObj, tag) in numTagRecords"
                                >
                                    <li :class="{ active: filterTag === tag }">
                                        <a
                                            @click="
                                                filterTagResults($event, tag)
                                            "
                                            :title="tag"
                                            >{{ tag }} ({{ tagObj.total }})</a
                                        >
                                    </li>
                                    <li
                                        :class="{ active: filterTag === tag }"
                                        class="subtag"
                                        v-for="(count, tag) in tagObj.tags"
                                    >
                                        <a
                                            @click="
                                                filterTagResults($event, tag)
                                            "
                                            :title="tag"
                                            >{{ tag }} ({{ count }})</a
                                        >
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </div>
                    <!--End of filter-->
                    <!--Search History-->
                    <div class="module-right side-list history">
                        <h2>
                            <i class="fa fa-history" aria-hidden="true"></i
                            >Recent searches
                        </h2>
                        <div
                            class="list"
                            v-for="recent in recentSearches"
                            @click="doRecentSearch(recent)"
                        >
                            {{ recent }}
                        </div>
                    </div>
                    <!--end of search history-->
                </div>
                <!--End of Right col-->
            </div>
        </div>
        <!--End of resultes-->
        <!--footer-->
        <footer>
            <div class="serp-footer">
                <div class="logoFooter">
                    <img :src="branding.Logo" /><span
                        ><span id="brandDetails"
                            ><i class="fa fa-copyright" aria-hidden="true"></i>
                            {{ branding.Trademark }}</span
                        >
                    </span>
                </div>
                <ul>
                    <li>
                        <a
                            :href="
                                `https://${branding.RootDomain}/terms-of-service/`
                            "
                            >Terms of Service
                        </a>
                    </li>
                    <li>
                        <a
                            :href="
                                `https://${branding.RootDomain}/privacy-policy/`
                            "
                            >Privacy</a
                        >
                    </li>
                    <li>
                        <a :href="`https://${branding.RootDomain}/contact-us/`"
                            >Contact Us</a
                        >
                    </li>
                </ul>
            </div>
        </footer>
        <!--end of footer-->
    </div>
    <!-- End of main wrapper -->
</template>

<script>
import bp from "babel-polyfill";
import queryString from "query-string";
import "whatwg-fetch";
import Humanize from "humanize-plus";
import { getSetting, setSetting, getGuid } from "../storage";
import { WEB_SEARCH_URL, openUrl, SEARCH_API_URL } from "../urls";
import FirstRun from "../components/FirstRun.vue";
import AutoCompleteInput from "../components/AutoCompleteInput.vue";
import IntroBox from "../components/IntroBox.vue";
import {
    getBranding,
    PRODUCT_SEARCH_PROVIDERS,
    altSearchProvidersUrls,
} from "../branding";
import { documentReady, isFirefox } from "../common";
import "../analytics";

function validatePop(name) {
    switch (name) {
        case "intro":
        case "i":
            return "intro";
        case "t":
        case "tutorial":
            return "tutorial";
        case "it":
            return "intro-tutorial";
    }
    return false;
}

export default {
    name: "serp",
    components: {
        "first-run": FirstRun,
        "auto-complete-input": AutoCompleteInput,
        "intro-box": IntroBox,
    },
    data() {
        const qs = queryString.parse(window.location.search);
        return {
            openUrl,
            recentSearches: [],
            productSearch: true,
            filterTag: null,
            records: [],
            tags: [],
            categoryTags: [],
            searchCompleted: false,
            query: "",
            wQuery: "",
            queryError: false,
            searchTerm: "",
            guid: qs.guid,
            productEngine: qs.engine,
            whichPop: validatePop(qs.pop) || false,
            branding: getBranding(),
            isFirefox,
        };
    },
    mounted() {
        documentReady.then(async () => {
            this.recentSearches =
                JSON.parse(getSetting("recentSearches")) || [];

            try {
                this.guid = await getGuid();
            } catch (e) {
                // no extension or guid query paraneter
            }

            this.$refs.queryInput.focus();
            this.categorySearch();
            this.handleSearchUrl();
        });
    },
    async created() {
        window.onpopstate = () => {
            this.categorySearch();
            this.handleSearchUrl();
        };
    },
    methods: {
        async startWebSearch() {
            const searchParams = {
                k: this.wQuery,
                guid: this.guid,
            };
            await openUrl(
                `${WEB_SEARCH_URL}?${queryString.stringify(searchParams)}`,
                false
            );
        },
        validateSearch(withFocus) {
            const { query } = this;
            let errs = false;
            if (this.query == "") {
                errs = errs || {};
                errs.query = "This field is required, Please enter";
            }
            this.queryError = errs;
            if (withFocus) {
                if (errs.query) this.$refs.queryInput.focus();
            }
            return errs === false;
        },
        async doSearch(term, addToHistory = true) {
            if (this.productEngine) {
                await openUrl(
                    PRODUCT_SEARCH_PROVIDERS[this.productEngine].url.replace(
                        "{{QUERY}}",
                        term
                    ),
                    false
                );
            } else {
                if (addToHistory) {
                    if (this.recentSearches.unshift(term) > 5) {
                        this.recentSearches.pop();
                    }
                    setSetting(
                        "recentSearches",
                        JSON.stringify(this.recentSearches)
                    );
                }
                this.filterTag = null;
                this.searchCompleted = false;
                const qs = {
                    q: term,
                    perPage: 1000,
                };
                const recordsReq = await fetch(
                    `${SEARCH_API_URL}?${queryString.stringify(qs)}`
                );
                if (recordsReq.ok) {
                    const recordsRes = await recordsReq.json();
                    if (recordsRes && recordsRes.main) {
                        if (term && recordsRes.main.records) {
                            this.records = recordsRes.main.records;
                            this.searchTerm = recordsRes.main.query;
                            if (recordsRes.main.tags) {
                                this.tags = recordsRes.main.tags;
                            }
                        } else if (!term && recordsRes.main.tags) {
                            this.categoryTags = recordsRes.main.tags;
                        }
                    }
                    this.searchCompleted = true;
                }
            }
        },
        filterTagResults(ev, tag) {
            ev.preventDefault();
            this.filterTag = tag;
        },
        resetFilterTag() {
            this.filterTag = null;
        },
        toWebSearch() {
            this.productSearch = false;
            this.$nextTick(() => this.$refs.webSearch.focus());
        },
        toProductSearch() {
            this.productSearch = true;
            this.$nextTick(() => {
                this.$refs.queryInput.focus();
            });
        },
        async handleSearchUrl() {
            const qs = queryString.parse(window.location.search);
            const { q } = qs;
            this.query = q || "";
            if (this.query) {
                await this.doSearch(this.query, false);
            }
        },
        async categorySearch() {
            await this.doSearch();
        },
        onNextPop(tag) {
            this.whichPop = validatePop(tag) || false;
        },
        onCloseHelp() {
            switch (this.whichPop) {
                case "intro-tutorial":
                    this.whichPop = validatePop("i");
                    return;
            }
            this.whichPop = false;
        },
        async doRecentSearch(term) {
            this.query = term;
            await this.doSearch(this.query);
        },
        async onSugSelected() {
            if (!this.validateSearch(true)) return;
            await this.doSearch(this.query);
        },
        microdataFields(data) {
            Object.keys(data).forEach(
                key => data[key] === "" && delete data[key]
            );
            return data;
        },
    },
    computed: {
        hasSearchResults() {
            return this.numResults > 0;
        },
        numResults() {
            return this.filteredSearchResults.length;
        },
        filteredSearchResults() {
            if (!this.filterTag) {
                return this.records;
            }
            return this.records.filter(record =>
                record.tags.includes(this.filterTag)
            );
        },
        numTagRecords() {
            if (this.tags) return this.tags;
            const tags = {};
            for (const record of this.records) {
                for (const tag of record.tags) {
                    const tagTotal = tags[tag] && tags[tag].total;
                    tags[tag] = { total: ++tagTotal || 1 };
                }
            }
            return tags;
        },
        altSearchUrls() {
            return altSearchProvidersUrls(this.query);
        },
    },
};
</script>

<style>
@import "./css/serp.css";
</style>
